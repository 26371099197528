import Vue from "vue";
import VueRouter from "vue-router";
import userCenter from "../views/userCenter/userCenter";

Vue.use(VueRouter);

const routes = [
  // 首页
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index/index'),
    meta: {
      title: '科英科研平台'
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/index/search'),
    meta: {
      title: '搜索-科英科研平台'
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/index/index'),
    redirect: {
      name: 'index'
    },
    meta: {
      title: '科英科研平台'
    }
  },
  {
    path: '/retrieval',
    name: 'retrieval',
    component: () => import('@/views/retrieval/index'),
    meta: {
      title: '信息检索-科英科研平台'
    },
    redirect: {
      name: 'retrievalList'
    },
    children: [
      {
        path: 'list',
        name: 'retrievalList',
        component: () => import('@/views/retrieval/list'),
        meta: {
          title: '信息检索-科英科研平台'
        },
      },
      {
        path: 'detail',
        name: 'retrievalDetail',
        component: () => import('@/views/retrieval/detail'),
        meta: {
          title: '信息检索-科英科研平台'
        },
      },
    ]
  },
  // {
  //   path: '/retrieval/detail',
  //   name: 'retrievalDetail',
  //   component: () => import('@/views/retrieval/detail'),
  //   meta: {
  //     title: '计算模拟-科英科研平台'
  //   },
  // },
  {
    path: '/simulate',
    name: 'simulate',
    component: () => import('@/views/simulate/index'),
    meta: {
      title: '计算模拟-科英科研平台'
    },
  },
  {
    path: '/simulate/detail',
    name: 'simulateDetail',
    component: () => import('@/views/simulate/detail'),
    meta: {
      title: '计算模拟-科英科研平台'
    },
  },
  {
    path: '/funding',
    name: 'funding',
    component: () => import('@/views/funding/index'),
    meta: {
      title: '经费申请-科英科研平台'
    },
  },
  {
    path: '/funding/detail',
    name: 'fundingDetail',
    component: () => import('@/views/funding/detail'),
    meta: {
      title: '经费申请-科英科研平台'
    },
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('@/views/projects/index'),
    meta: {
      title: '企业项目-科英科研平台'
    },
  },
  {
    path: '/projects/detail',
    name: 'projectsDetail',
    component: () => import('@/views/projects/detail'),
    meta: {
      title: '企业项目-科英科研平台'
    },
  },
  {
    path: '/analysis',
    name: 'analysis',
    component: () => import('@/views/analysis/index'),
    meta: {
      title: '分析测试-科英科研平台'
    },
  },
  {
    path: '/analysis/detail',
    name: 'analysisDetail',
    component: () => import('@/views/analysis/detail'),
    meta: {
      title: '分析测试-科英科研平台'
    },
  },
  {
    path: '/shop',
    name: 'shop',
    component: () => import('@/views/shop/index'),
    meta: {
      title: '科研商城-科英科研平台'
    }
  },
  {
    path: '/shop/detail',
    name: 'shopDetail',
    component: () => import('@/views/shop/detail'),
    meta: {
      title: '科研商城-科英科研平台'
    }
  },
  {
    path: '/shop/cart',
    name: 'shopCart',
    component: () => import('@/views/shop/cart'),
    meta: {
      title: '购物车-科英科研平台'
    }
  },
  // {
  //   path: '/outcome',
  //   name: 'outcome',
  //   component: () => import('@/views/outcome/index'),
  //   meta: {
  //     title: '成果评价-科英科研平台'
  //   }
  // },
  // {
  //   path: '/outcome/detail',
  //   name: 'outcomeDetail',
  //   component: () => import('@/views/outcome/detail'),
  //   meta: {
  //     title: '成果评价-科英科研平台'
  //   }
  // },
  {
    path: '/title',
    name: 'title',
    component: () => import('@/views/title/index'),
    meta: {
      title: '称号申请-科英科研平台'
    }
  },
  {
    path: '/title/detail',
    name: 'titleDetail',
    component: () => import('@/views/title/detail'),
    meta: {
      title: '称号申请-科英科研平台'
    }
  },
  {
    path: '/thesis',
    name: 'thesis',
    component: () => import('@/views/thesis/index'),
    meta: {
      title: '论文专利-科英科研平台'
    }
  },
  {
    path: '/thesis/list',
    name: 'thesisList',
    component: () => import('@/views/thesis/list'),
    meta: {
      title: '论文专利-科英科研平台'
    }
  },
  {
    path: '/thesis/detail',
    name: 'thesisDetail',
    component: () => import('@/views/thesis/detail'),
    meta: {
      title: '论文专利-科英科研平台'
    }
  },
  {
    path: '/paperPolishing',
    name: 'paperPolishing',
    component: () => import('@/views/thesis/paperPolishing'),
    meta: {
      title: '论文润色-科英科研平台'
    }
  },
  {
    path: '/paperSubmission',
    name: 'paperSubmission',
    component: () => import('@/views/thesis/paperSubmission'),
    meta: {
      title: '论文投稿-科英科研平台'
    }
  },
  {
    path: '/academicTranslation',
    name: 'academicTranslation',
    component: () => import('@/views/thesis/academicTranslation'),
    meta: {
      title: '学术翻译-科英科研平台'
    }
  },
  // {
  //   path: '/meeting',
  //   name: 'meeting',
  //   component: () => import('@/views/meeting/index'),
  //   meta: {
  //     title: '会议服务-科英科研平台'
  //   },
  // },
  {
    path: '/meeting/post',
    name: 'meetingPost',
    component: () => import('@/views/meeting/post'),
    meta: {
      title: '会议举办-科英科研平台'
    },
  },
  {
    path: '/meeting/detail',
    name: 'meetingDetail',
    component: () => import('@/views/meeting/detail'),
    meta: {
      title: '会议详情-科英科研平台'
    },
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/news/index'),
    meta: {
      title: '科研资讯-科英科研平台'
    },
  },
  {
    path: '/news/detail',
    name: 'news',
    component: () => import('@/views/news/detail'),
    meta: {
      title: '科研资讯-科英科研平台'
    },
  },
  {
    path: '/patent',
    name: 'patent',
    component: () => import('@/views/patent/index'),
    meta: {
      title: '会议详情-科英科研平台'
    },
  },
  {
    path: '/patent/detail',
    name: 'patentDetail',
    component: () => import('@/views/patent/detail'),
    meta: {
      title: '会议详情-科英科研平台'
    },
  },
  {
    path: '/patent/market',
    name: 'patentMarket',
    component: () => import('@/views/patent/market'),
    meta: {
      title: '专利转让-科英科研平台'
    },
  },
  {
    path: '/patent/market/detail',
    name: 'patentMarketDetail ',
    component: () => import('@/views/patent/marketDetail'),
    meta: {
      title: '专利转让详情-科英科研平台'
    },
  },
  {
    path: '/patent/buy',
    name: 'patentBuy',
    component: () => import('@/views/patent/buy'),
    meta: {},
  },
  {
    path: '/position',
    name: 'position',
    component: () => import('@/views/position/index'),
    meta: {
      title: '硕博招聘-科英科研平台'
    },
  },
  {
    path: '/position/list',
    name: 'positionList',
    component: () => import('@/views/position/list'),
    meta: {
      title: '硕博招聘-科英科研平台'
    },
  },
  {
    path: '/position/detail',
    name: 'positionDetail',
    component: () => import('@/views/position/detail'),
    meta: {
      title: '职位详情-科英科研平台'
    },
  },
  {
    path: '/position/company',
    name: 'positionCompany',
    component: () => import('@/views/position/company'),
    meta: {
      title: '公司介绍-科英科研平台'
    },
  },

  {
    path: '/expert',
    name: 'expert',
    component: () => import('@/views/expert/index'),
    meta: {
      title: '专家学者库-科英科研平台'
    },
  },

  {
    path: '/expert/detail',
    name: 'expertDetail',
    component: () => import('@/views/expert/detail'),
    meta: {
      title: '专家详情-科英科研平台'
    },
  },
  {
    path: '/video',
    name: 'video',
    component: () => import('@/views/video/index'),
    meta: {
      title: '科英学院-科英科研平台'
    },
  },

  {
    path: '/video/detail',
    name: 'videoDetail',
    component: () => import('@/views/video/detail'),
    meta: {
      title: '课程详情-科英科研平台'
    },
  },
  {
    path: '/video/buy',
    name: 'videoBuy',
    component: () => import('@/views/video/buy'),
    meta: {
      title: '购买课程-科英科研平台'
    },
  },

  //平台相关
  {
    path: '/keying/about',
    name: 'keyingAbout',
    component: () => import('@/views/keying/about'),
    meta: {
      title: '关于我们-科英科研平台'
    },
  },
  {
    path: '/keying/cooperate',
    name: 'keyingCooperate',
    component: () => import('@/views/keying/cooperate'),
    meta: {
      title: '合作入驻-科英科研平台'
    },
  },
  {
    path: '/keying/recruit',
    name: 'keyingRecruit',
    component: () => import('@/views/keying/recruit'),
    meta: {
      title: '科英招聘-科英科研平台'
    },
  },
  {
    path: '/keying/recruit_detail',
    name: 'keyingRecruitDetail',
    component: () => import('@/views/keying/recruit-detail'),
    meta: {
      title: '职位详情-科英科研平台'
    },
  },
  {
    path: '/keying/news',
    name: 'keyingNews',
    component: () => import('@/views/keying/news'),
    meta: {
      title: '科英资讯-科英科研平台'
    },
  },
  {
    path: '/keying/news-detail',
    name: 'keyingNewsDetail',
    component: () => import('@/views/keying/news-detail'),
    meta: {
      title: '资讯详情-科英科研平台'
    },
  },
  {
    path: '/keying/member',
    name: 'keyingMember',
    component: () => import('@/views/keying/member'),
    meta: {
      title: '科英会员-科英科研平台'
    },
  },
  {
    path: '/order/settle',
    name: 'orderSettle',
    component: () => import('@/views/order/settle'),
    meta: {
      isToken: true,
      title: '预约下单-科英科研平台'
    }
  },
  {
    path: '/users/aliPay/paynotify',
    name: 'aliPayNotify',
    component: () => import('@/views/userCenter/aliPayNotify'),
    meta: {
      isToken: true,
      title: '支付结果-科英科研平台'
    }
  },
  {
    path: '/demand',
    name: 'demand',
    component: () => import('@/views/demand/index'),
    meta: {
      isToken: false,
      title: '提交需求-科英科研平台'
    }
  },

  // 用户中心
  {
    path: '/userCenter',
    name: 'userCenter',
    component: userCenter,
    redirect: {
      name: 'overview'
    },
    meta: {
      isToken: false,
      title: '会员中心-科英科研平台'
    },
    children: [
      //用户信息
      {
        path: '/overview',
        name: 'overview',
        component: () => import('@/views/userCenter/account/overview'),
        meta: {
          isToken: false,
          title: '用户中心-科英科研平台'
        }
      },
      {
        path: '/recharge',
        name: 'Recharge',
        component: () => import('@/views/userCenter/recharge'),
        meta: {
          isToken: false,
          title: '预存-科英科研平台'
        },
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/userCenter/account/profile'),
        meta: {
          isToken: false,
          title: '个人资料-科英科研平台'
        }
      },
      {
        path: '/credit',
        name: 'credit',
        component: () => import('@/views/userCenter/account/credit'),
        meta: {
          isToken: false,
          title: '信用额度-科英科研平台'
        }
      },
      {
        path: '/myOrder',
        name: 'myOrder',
        component: () => import('@/views/userCenter/order/myOrder'),
        meta: {
          isToken: false,
          title: '我的订单-科英科研平台'
        }
      },
      {
        path: '/myOrderDetail',
        name: 'myOrderDetail',
        component: () => import('@/views/userCenter/order/myOrderDetail'),
        meta: {
          isToken: false,
          title: '订单详情-科英科研平台'
        }
      },
      {
        path: '/myDraft',
        name: 'myDraft',
        component: () => import('@/views/userCenter/order/draft'),
        meta: {
          isToken: false,
          title: '草稿箱-科英科研平台'
        }
      },
      {
        path: '/myShopOrder',
        name: 'myShopOrder',
        component: () => import('@/views/userCenter/order/shopOrder'),
        meta: {
          isToken: false,
          title: '商城订单-科英科研平台'
        }
      },
      {
        path: '/myShopOrderDetail',
        name: 'myShopOrderDetail',
        component: () => import('@/views/userCenter/order/shopOrderDetail'),
        meta: {
          isToken: false,
          title: '商城订单详情-科英科研平台'
        }
      },
      {
        path: '/myCollect',
        name: 'myCollect',
        component: () => import('@/views/userCenter/collect'),
        meta: {
          isToken: false,
          title: '我的收藏-科英科研平台'
        }
      },
      {
        path: '/myProject',
        name: 'myProject',
        component: () => import('@/views/userCenter/project'),
        meta: {
          isToken: false,
          title: '项目管理-科英科研平台'
        }
      },
      {
        path: '/myProjectDetail',
        name: 'myProjectDetail',
        component: () => import('@/views/userCenter/projectDetail'),
        meta: {
          isToken: false,
          title: '项目详情-科英科研平台'
        }
      },
      {
        path: '/myPatent',
        name: 'myPatent',
        component: () => import('@/views/userCenter/patent'),
        meta: {
          isToken: false,
          title: '专利管理-科英科研平台'
        }
      },
      {
        path: '/myPatentOrderDetail',
        name: 'myPatentOrderDetail',
        component: () => import('@/views/userCenter/order/patentDetail'),
        meta: {
          isToken: false,
          title: '专利管理-科英科研平台'
        }
      },
      {
        path: '/myCollegeOrderDetail',
        name: 'myCollegeOrderDetail',
        component: () => import('@/views/userCenter/order/collegeDetail'),
        meta: {
          isToken: false,
          title: '专利管理-科英科研平台'
        }
      },
      {
        path: '/myMeeting',
        name: 'myMeeting',
        component: () => import('@/views/userCenter/meeting'),
        meta: {
          isToken: false,
          title: '会议管理-科英科研平台'
        }
      },
      {
        path: '/companyEdit',
        name: 'companyEdit',
        component: () => import('@/views/userCenter/companyEdit'),
        meta: {
          isToken: false,
          title: '编辑公司信息-科英科研平台'
        }
      },
      {
        path: '/myPosition',
        name: 'myPosition',
        component: () => import('@/views/userCenter/recruit/position'),
        meta: {
          isToken: false,
          title: '职位管理-科英科研平台'
        }
      },
      {
        path: '/myApply',
        name: 'myApply',
        component: () => import('@/views/userCenter/recruit/apply'),
        meta: {
          isToken: false,
          title: '应聘管理-科英科研平台'
        }
      },
      {
        path: '/mySend',
        name: 'mySend',
        component: () => import('@/views/userCenter/expert/send'),
        meta: {
          isToken: false,
          title: '我的派单-科英科研平台'
        }
      },
      {
        path: '/myPatentOrder',
        name: 'myPatentOrder',
        component: () => import('@/views/userCenter/order/patent'),
        meta: {
          isToken: false,
          title: '专利订单-科英科研平台'
        }
      },
      {
        path: '/myCollegeOrder',
        name: 'myCollegeOrder',
        component: () => import('@/views/userCenter/order/college'),
        meta: {
          isToken: false,
          title: '专利订单-科英科研平台'
        }
      },
      {
        path: '/mySendDetail',
        name: 'mySendDetail',
        component: () => import('@/views/userCenter/expert/sendOrderDetail'),
        meta: {
          isToken: false,
          title: '我的派单详情-科英科研平台'
        }
      },
      {
        path: '/myOwnProject',
        name: 'myOwnProject',
        component: () => import('@/views/userCenter/expert/project'),
        meta: {
          isToken: false,
          title: '我的项目-科英科研平台'
        }
      },
      {
        path: '/myOwnExpertOverview',
        name: 'expertOverview',
        component: () => import('@/views/userCenter/expert/overview'),
        meta: {
          title: '专家中心-科英科研平台'
        },
      },

      {
        path: '/myOwnProjectDetail',
        name: 'myOwnProjectDetail',
        component: () => import('@/views/userCenter/expert/projectDetail'),
        meta: {
          isToken: false,
          title: '我的项目详情-科英科研平台'
        }
      },
      {
        path: '/myResume',
        name: 'myResume',
        component: () => import('@/views/userCenter/expert/resume'),
        meta: {
          isToken: false,
          title: '我的简历-科英科研平台'
        }
      },
      {
        path: '/mySupply',
        name: 'mySupply',
        component: () => import('@/views/userCenter/expert/supply'),
        meta: {
          isToken: false,
          title: '我的应聘-科英科研平台'
        }
      },
      {
        path: '/myInvoice',
        name: 'myInvoice',
        component: () => import('@/views/userCenter/invoice'),
        meta: {
          isToken: false,
          title: '发票管理-科英科研平台'
        }
      },
      {
        path: '/team',
        name: 'team',
        component: () => import('@/views/userCenter/team/index'),
        meta: {
          isToken: false,
          title: '我的团队-科英科研平台'
        }
      },
      {
        path: '/getcoupon',
        name: 'getcoupon',
        component: () => import('@/views/userCenter/getcoupon'),
        meta: {
          isToken: false,
          title: '领取优惠券-科英科研平台'
        }
      },
      {
        path: '/coupon',
        name: 'coupon',
        component: () => import('@/views/userCenter/coupon'),
        meta: {
          isToken: false,
          title: '我的优惠券-科英科研平台'
        }
      },
      {
        path: '/team/detail',
        name: 'teamDetail',
        component: () => import('@/views/userCenter/team/detail'),
        meta: {
          isToken: false,
          title: '团队详情-科英科研平台'
        }
      }
    ]
  },
  // 登录
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login'),
    meta: {
      title: '登录-科英科研平台'
    }
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  to.meta.title && (document.title = to.meta.title);
  if (to.path === "/login") {
    next();
  } else {
    // 权限控制
    next();
  }
});

export default router;
