/**
 * 接口统一管理
 */
import {get, post,put,del} from './axios';
import axios from "axios";

export const apiGetProductList = params => get('/basis/kyIndex/getProductList', params);
export const apiProductInfo = id => get('/basis/kyIndex/productInfo/' + id);
export const apiCartList = params => get('/users/kyUsersCart/getCartsList', params);
export const apiBrandList = cid => get('/basis/kyIndex/brandList/' + cid, {});
export const apiCartNum = params => get('/users/kyUsersCart/countNum', params);
export const apiResearchMallOrderCount = params => get('/users/kyResearchMallOrder/orderCount', params);
export const apiResearchMallOrderList = params => get('/users/kyResearchMallOrder/orderList', params);
export const apiResearchMallOrderDetail = id => get('/users/kyResearchMallOrder/getOrderInfo/' + id, {});
export const apiCancelResearchMallOrder = id => put('/users/kyResearchMallOrder/cancelOrder/' + id, {});
export const apiAddCart = params => post('/users/kyUsersCart/addCart', params);
export const apiCreateResearchMallOrder = params => post('/users/kyResearchMallOrder/addOrder', params);
export const apiPayResearchMallOrder = params => post('/users/kyResearchMallOrder/payOrder', params);
export const apiUpdateCart = params => put('/users/kyUsersCart/updateCart', params);
export const apiConfirmReceipt = params => put('/users/kyResearchMallOrder/confirmReceipt', params);
export const apiEvaluateOrder = params => put('/users/kyResearchMallOrder/evaluateOrder', params);
export const apiDelCart = ids => del('/users/kyUsersCart/delCart/'+ ids);
